@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap');
.App {
  /* font-family: sans-serif; */
  /* text-align: center; */
  width: 100vw;
  height: 100vh;
  /* position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
}
html, body, #app, #app>div {
  height: 100%
}
a{
  all: unset;
  cursor: pointer;
}